import { WatcherID, UserID } from "@entropyinternet/alertsboo-helpers";
import { NODE_ENV } from "./env";

const devConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "http://localhost:8080/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `http://localhost:8080/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `http://localhost:8080/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) => `http://localhost:8080/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `http://localhost:8080/v1/watchers/${watcherID}/logs/list`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `http://localhost:8080/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) => `http://localhost:8080/v1/users/${userID}/change-sub`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `http://localhost:8080/v1/billing/${userID}/setup-intent`,
    },
    stripeRemoveCard: {
      url: (userID) => `http://localhost:8080/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyCksr0aZmJhZ_ED6nKQWAeaNAShUgwfeOE",
    authDomain: "alertsboo-app.firebaseapp.com",
    projectId: "alertsboo-app",
    storageBucket: "alertsboo-app.appspot.com",
    messagingSenderId: "167055860552",
    appId: "1:167055860552:web:eff98872c82867f8c5097d",
    measurementId: "G-G9MCB9ZK9P",
  },
  STRIPE: {
    customerPortalUrl:
      "https://billing.stripe.com/p/login/test_7sI3cV0eM1WDgvufYY",
    publishKey:
      "pk_test_51McyYLBbKljWimkIR6ulMVmH6tRKMLCQfMEeImPqTrl9pkfT7z3h8aEchJ4rPg31ppRdZEtJ3UXMPCc6h9262Umy00z6Fn1KAz",
  },
};
const stagingConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/watchers/${watcherID}/logs/list`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/users/${userID}/change-sub`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/billing/${userID}/setup-intent`,
    },
    stripeRemoveCard: {
      url: (userID) =>
        `https://alertsboo-server-56jpsgv4uq-uc.a.run.app/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyCksr0aZmJhZ_ED6nKQWAeaNAShUgwfeOE",
    authDomain: "alertsboo-app.firebaseapp.com",
    projectId: "alertsboo-app",
    storageBucket: "alertsboo-app.appspot.com",
    messagingSenderId: "167055860552",
    appId: "1:167055860552:web:eff98872c82867f8c5097d",
    measurementId: "G-G9MCB9ZK9P",
  },
  STRIPE: {
    customerPortalUrl:
      "https://billing.stripe.com/p/login/test_7sI3cV0eM1WDgvufYY",
    publishKey:
      "pk_test_51McyYLBbKljWimkIR6ulMVmH6tRKMLCQfMEeImPqTrl9pkfT7z3h8aEchJ4rPg31ppRdZEtJ3UXMPCc6h9262Umy00z6Fn1KAz",
  },
};
const prodConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "https://api.alerts.boo/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `https://api.alerts.boo/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `https://api.alerts.boo/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) => `https://api.alerts.boo/v1/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `https://api.alerts.boo/v1/watchers/${watcherID}/logs/list`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `https://api.alerts.boo/v1/billing/${userID}/setup-intent`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `https://api.alerts.boo/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) => `https://api.alerts.boo/v1/users/${userID}/change-sub`,
    },
    stripeRemoveCard: {
      url: (userID) =>
        `https://api.alerts.boo/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyAEb28HbXfqsDd8mNP3XwRswYe0LbOhvi8",
    authDomain: "alertsboo-prod.firebaseapp.com",
    projectId: "alertsboo-prod",
    storageBucket: "alertsboo-prod.appspot.com",
    messagingSenderId: "244049961731",
    appId: "1:244049961731:web:288e118a4e6e63641c3f36",
    measurementId: "G-DPLLKW1SFX",
  },
  STRIPE: {
    customerPortalUrl: "https://billing.stripe.com/p/login/fZecQubkxcwOgDuaEE",
    publishKey:
      "pk_live_51McyYLBbKljWimkIVl1fpkwjqXmr3QF2yIgnh93AWMXTYjL52DL7YHjV6wakfE0lNBoD2uzx6OVnAULfGjXl1a9700kbt34b6y",
  },
};

export interface RestApiRateLimit {
  windowMs: number;
  max: number;
}
interface ConfigEnv {
  REST_API: {
    createWatcher: {
      url: string;
    };
    updateWatcher: {
      url: (watcherID: WatcherID) => string;
    };
    listWatchers: {
      url: string;
    };
    rotateToken: {
      url: (userID: UserID) => string;
    };
    listLogsOfWatcher: {
      url: (watcherID: WatcherID) => string;
    };
    stripeSetupIntent: {
      url: (userID: UserID) => string;
    };
    stripeRemoveCard: {
      url: (userID: UserID) => string;
    };
    stripePaymentIntent: {
      url: (userID: UserID) => string;
    };
    changeSubscription: {
      url: (userID: UserID) => string;
    };
  };
  FIREBASE: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  STRIPE: {
    customerPortalUrl: string;
    publishKey: string;
  };
}

export default (() => {
  // @ts-ignore
  if (NODE_ENV === "production") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
  }

  console.log(`NODE_ENV: ${NODE_ENV}`);
  // @ts-ignore
  if (NODE_ENV === "production") {
    return prodConfig;
    // @ts-ignore
  } else if (NODE_ENV === "staging") {
    return stagingConfig;
  } else if (NODE_ENV === "development") {
    return devConfig;
  }
  return devConfig;
})();

export enum BUILD_ENV_TARGET {
  PRODUCTION = "production",
  STAGING = "staging",
  DEVELOPMENT = "development",
}
