// WatcherPage.tsx
import React, { useEffect } from "react";
import { useUserStore } from "../../store";
import { getAuth, signOut } from "firebase/auth";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Result,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  EyeOutlined,
  QuestionCircleFilled,
  CodeOutlined,
  LinkOutlined,
  DownOutlined,
  PlayCircleFilled,
  PauseCircleFilled,
  ThunderboltOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config.env";
import {
  Alert_Firestore,
  WatcherID,
  WatcherStatus,
  Watcher_Firestore,
  LogStatus,
  Log_Firestore,
  UserID,
} from "@entropyinternet/alertsboo-helpers";
import {
  loadFirestoreWatcher,
  loadFirestoreWatchers,
  resetWatchers,
} from "../../store/watchers.store";
import {
  WatcherStatusToAntdColor,
  PlatformToLogo,
} from "../WatchersPage/WatchersPage";
import dayjs from "dayjs";
import $Vertical from "../../components/utility/Vertical";
import axios from "axios";
import { InstagramSampleAlert } from "../../samples/instagram-alert.sample";

export const checkUrlStringValid = (url: string): boolean => {
  if (!url) {
    return false;
  }
  try {
    console.log(`checkUrlStringValid: url = ${url}`);
    const urlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const isValid = urlRegex.test(url);
    const parsedUrl = new URL(url);
    console.log("isValid = ", isValid);
    console.log("parsedUrl = ", parsedUrl);
    if (parsedUrl && isValid) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

const WatcherPage = () => {
  const [webhookUrl, setWebhookUrl] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [recentLogs, setRecentLogs] = React.useState<Log_Firestore[]>([]);
  const { firebaseUser } = useUserStore((state) => state);
  const [promptFilter, setPromptFilter] = React.useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [watcher, setWatcher] = React.useState<Watcher_Firestore>();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const params = useParams();
  const watcherID = params.watcherID;

  const getRecentLogsForWatcher = async (
    watcherID: WatcherID
  ): Promise<Log_Firestore[]> => {
    if (!firebaseUser) {
      return [];
    }
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.listLogsOfWatcher.url(watcherID),
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            limit: 10,
          }),
        }
      );
      const result = await response.json();
      const { success, message, data } = result;
      if (!success) {
        setErrorMessage(message);
      } else {
        console.log("list logs of watcher result", result);
      }
      return data.logs as Log_Firestore[];
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
      return [];
    }
  };

  useEffect(() => {
    if (watcherID) {
      const run = async () => {
        const [watcher, logs] = await Promise.all([
          await loadFirestoreWatcher(watcherID as WatcherID),
          await getRecentLogsForWatcher(watcherID as WatcherID),
        ]);
        setWatcher(watcher);
        setWebhookUrl(watcher.webhookUrl);
        setPromptFilter(watcher.filterPrompt);
        setRecentLogs(logs);
      };
      run();
    }
  }, [watcherID]);

  if (!watcherID) {
    return <div>Invalid Watcher</div>;
  }
  if (!watcher) {
    return (
      <Spin tip="Loading...">
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </Spin>
    );
  }

  if (!firebaseUser) {
    return <div>Not Logged In</div>;
  }

  const updateWatcher = async () => {
    if (webhookUrl && !checkUrlStringValid(webhookUrl)) {
      setErrorMessage("Invalid Webhook URL");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.updateWatcher.url(watcherID as WatcherID),
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            webhookUrl,
            promptFilter,
          }),
        }
      );
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        setLoading(false);
      } else {
        setWebhookUrl("");
        setLoading(false);
        console.log("updateWatcher result", result);
        messageApi.open({
          type: "success",
          content: "Successfully Updated Watcher",
        });
        await resetWatchers(firebaseUser.uid as UserID);
        const watcher = await loadFirestoreWatcher(watcherID as WatcherID);
        setWatcher(watcher);
        setWebhookUrl(watcher.webhookUrl);
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    console.log("handleMenuClick", e);
    const key = e.key;
    if (key === "delete") {
      setConfirmDeleteModal(true);
      return;
    }
    setLoading(true);
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.updateWatcher.url(watcherID as WatcherID),
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            status:
              key === "pause" ? WatcherStatus.paused : WatcherStatus.awaiting,
          }),
        }
      );
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        setLoading(false);
      } else {
        setWebhookUrl("");
        setLoading(false);
        console.log("updateWatcher result", result);
        messageApi.open({
          type: "success",
          content: "Successfully Updated Watcher",
        });
        await resetWatchers(firebaseUser.uid as UserID);
        const watcher = await loadFirestoreWatcher(watcherID as WatcherID);
        console.log(`updated watcher`, watcher);
        setWatcher(watcher);
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
  };

  const deleteWatcher = async () => {
    setLoading(true);
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.updateWatcher.url(watcherID as WatcherID),
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            status: WatcherStatus.inactive,
          }),
        }
      );
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        setLoading(false);
      } else {
        setWebhookUrl("");
        setLoading(false);
        console.log("updateWatcher result", result);
        messageApi.open({
          type: "success",
          content: "Successfully Deleted Watcher",
        });
        await resetWatchers(firebaseUser.uid as UserID);
        navigate("/watchers");
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Resume",
      key: "resume",
      icon: <PlayCircleFilled />,
    },
    {
      label: "Pause Watcher",
      key: "pause",
      icon: <PauseCircleFilled />,
    },
    {
      label: "Delete Watcher",
      key: "delete",
      icon: <DeleteFilled />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const getWatcherStatusDetails = (status: WatcherStatus) => {
    if (status === WatcherStatus.pending) {
      return {
        color: `#f0f9ff`,
        description: `Pending. Please wait, this watcher will come online soon.`,
        tooltip: `New Watchers are in Pending and usually become active in 60 mins, up to 24 hours. Active watchers have a status of Awaiting or Active.`,
        userActionDisabled: true,
      };
    } else if (status === WatcherStatus.paused) {
      return {
        color: `#ebebebb8`,
        description: `Paused. Turn this back on to receive alerts.`,
        tooltip: `Paused watchers do not forward alerts or cost credits. You can turn this watcher back ON at any time to resume. It costs 5 credits to turn back on.`,
        userActionDisabled: false,
      };
    } else if (status === WatcherStatus.insufficient) {
      return {
        color: "#fff1f1",
        description: `Insufficient Funds. Watcher has been paused.`,
        tooltip:
          "This watchergeer was auto-paused due to insufficient funds. Watchers cost 5 credits/month. You can turn this watcher back ON if you have sufficient funds (cost 5 credits to turn back on)",
        userActionDisabled: false,
      };
    } else if (status === WatcherStatus.inactive) {
      return {
        color: `#ebebebb8`,
        description: `Inactive. This watcher was deactived by system.`,
        tooltip: `You cannot change this watcher's status. Message customer support for help.`,
        userActionDisabled: true,
      };
    } else if (status === WatcherStatus.problem) {
      return {
        color: "#fff1f1",
        description: `Technical issues. Please contact support`,
        tooltip: `Something went wrong with this watcher. Message customer support for help.`,
        userActionDisabled: true,
      };
    } else if (status === WatcherStatus.banned) {
      return {
        color: "#fff1f1",
        description: `Violation. Contact support.`,
        tooltip: `This watcher was flagged due to a violation of our terms of service. Message customer support for help.`,
        userActionDisabled: true,
      };
    } else if (status === WatcherStatus.awaiting) {
      return {
        color: `#e7ffdd`,
        description: `Active and awaiting first alert`,
        tooltip: `This watcher is active and will forward alerts to you when they are available. If you are not receiving expected alerts, please contact support.`,
        userActionDisabled: false,
      };
    } else if (status === WatcherStatus.active) {
      return {
        color: `#e7ffdd`,
        description: `Active and forwarding alerts`,
        tooltip: `This watcher is active and continues to receive alerts. You are being billed 5 credits/month plus alert fees. You can pause this watcher at any time to stop billing.`,
        userActionDisabled: false,
      };
    } else {
      return {
        color: `#ebebebb8`,
        description: `Unknown Status. Contact support.`,
        tooltip: `Nothing else is known at this time. Message customer support for help.`,
        userActionDisabled: true,
      };
    }
  };

  const sentSampleWebhook = async () => {
    if (!webhookUrl) {
      messageApi.warning("Please add a webhook URL first");
      return;
    }
    try {
      await axios.post(webhookUrl, InstagramSampleAlert);
      messageApi.info("Sent a sample alert to your webhook");
    } catch (e) {
      console.error("Error:", e);
      messageApi.error(
        "Error sending sample alert to your webhook. Is your webhook active?"
      );
    }
  };

  console.log("watcher.lastReceivedAt", watcher.lastReceivedAt);
  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          rowGap: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            width: "100%",
          }}
        >
          <span
            onClick={() => navigate(-1)}
            style={{ color: "gray", cursor: "pointer" }}
          >{`< Back`}</span>
          <a
            href={`https://docs.google.com/forms/d/e/1FAIpQLScP4F63NabF546qKG1XeGxLWGYPyNJazQHTEDibP7by_DhQSA/viewform?usp=pp_url&entry.107917806=${watcherID}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "gray", textDecoration: "none" }}
          >
            Report Issue
          </a>
        </div>

        <h1>Watcher</h1>
        <Badge dot status={WatcherStatusToAntdColor[watcher.status]}>
          <Avatar src={PlatformToLogo[watcher.platform]} />
        </Badge>
        <b>{watcher.alias}</b>

        <$Vertical
          style={{
            backgroundColor: getWatcherStatusDetails(watcher.status).color,
            padding: "20px",
            margin: "20px 0px",
            width: "auto",
            borderRadius: "10px",
          }}
        >
          <span>
            {getWatcherStatusDetails(watcher.status).description}
            <Tooltip title={getWatcherStatusDetails(watcher.status).tooltip}>
              <QuestionCircleFilled
                size={12}
                style={{ color: "gray", marginLeft: "5px" }}
              />
            </Tooltip>
          </span>
          <div style={{ marginTop: 10 }}>
            <Dropdown
              disabled={
                getWatcherStatusDetails(watcher.status).userActionDisabled
              }
              menu={menuProps}
            >
              <Button loading={loading}>
                <Space>
                  {watcher.status}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </$Vertical>

        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
            width: "100%",
          }}
        >
          <label>Link to Resource</label>
          <Input
            value={watcher.url}
            disabled
            addonAfter={
              <EyeOutlined
                onClick={() => {
                  // open in new tab
                  window.open(watcher.url, "_blank");
                }}
              />
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
            width: "100%",
          }}
        >
          <label>
            AI Prompt to Filter Alerts (optional)
            <Tooltip title="Text prompt to tell the Ai what type of content you want to get alerts on. Should ask the Ai to return true or false. Costs credits & max 2000 chars. Leave empty for no Ai prompt filter.">
              <QuestionCircleFilled
                size={12}
                style={{
                  color: "gray",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
            </Tooltip>
            {
              <span style={{ color: "gray", fontSize: "0.7rem" }}>
                {promptFilter.length}/2000 chars
              </span>
            }
          </label>
          <Input.TextArea
            value={promptFilter}
            onChange={(e) => setPromptFilter(e.target.value)}
            rows={4}
            maxLength={2000}
            placeholder="Return true if this user posts about..."
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
            width: "100%",
          }}
        >
          <label>Webhook (optional)</label>
          <Input
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            addonAfter={
              <Select
                value="Actions"
                onSelect={(v) => {
                  if (v === "Sample") {
                    sentSampleWebhook();
                  }
                }}
                style={{ width: "100px" }}
              >
                <Select.Option value="Sample">Sample</Select.Option>
              </Select>
            }
          />
        </div>
        <div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
        <Button
          type="primary"
          disabled={
            webhookUrl === watcher.webhookUrl &&
            promptFilter === watcher.filterPrompt
          }
          loading={loading}
          onClick={updateWatcher}
        >
          Update
        </Button>

        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            rowGap: "10px",
            width: "100%",
          }}
        >
          <b>Recent Activity</b>
          {recentLogs.length > 0 ? (
            recentLogs.map((log) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Tooltip
                    title={
                      log.status === LogStatus.finished
                        ? `This post matched your filter prompt and was sent to you as an alert. Consumed ${log.creditsConsumed} credits`
                        : `This post did NOT match your filter prompt and was ignored. Consumed ${log.creditsConsumed} credits`
                    }
                  >
                    <div style={{ display: "flex", columnGap: "10px" }}>
                      {log.status === LogStatus.finished ? (
                        <Badge status="success" />
                      ) : (
                        <Badge status="default" />
                      )}
                      <span>{log.alias}</span>
                      <span>
                        <ThunderboltOutlined style={{ marginRight: "5px" }} />
                        {`${log.creditsConsumed}`}
                      </span>
                    </div>
                  </Tooltip>

                  <div style={{ display: "flex", columnGap: "10px" }}>
                    <span>
                      {dayjs(log.createdAt._seconds * 1000).fromNow()}
                    </span>
                    <a href={log.url} target="_blank" rel="noreferrer">
                      <LinkOutlined />
                    </a>
                    <a
                      href={log.fullObjectUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CodeOutlined />
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <span style={{ color: "gray" }}>Nothing Yet</span>
          )}
        </div>
      </div>
      {confirmDeleteModal && (
        <Modal
          open={confirmDeleteModal}
          onOk={() => setConfirmDeleteModal(false)}
          onCancel={() => setConfirmDeleteModal(false)}
          footer={null}
        >
          <Result
            status="warning"
            title="Confirm Delete"
            subTitle="Are you sure you want to delete this Watcher? It cannot be undone."
            extra={[
              <Button key="cancel" onClick={() => setConfirmDeleteModal(false)}>
                Cancel
              </Button>,
              <Button
                type="primary"
                onClick={() => deleteWatcher()}
                danger
                key="confirm"
                loading={loading}
              >
                Delete
              </Button>,
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default WatcherPage;
