// BuyPage.tsx
import React, { useEffect, useState } from "react";
import { loadFirestoreUser, useUserStore } from "../../store";
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Input,
  InputNumber,
  Modal,
  Result,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Switch,
  Tag,
  message,
} from "antd";
import {
  ThunderboltFilled,
  ReloadOutlined,
  PauseOutlined,
  ShoppingFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  BillingPlan,
  UserID,
  graduatedPricing,
} from "@entropyinternet/alertsboo-helpers";
import config from "../../config.env";
import { useWatcherStore } from "../../store/watchers.store";
import { useMediaQuery } from "react-responsive";
import $Vertical from "../../components/utility/Vertical";
import $Horizontal from "../../components/utility/Horizontal";
import { loadStripe, StripeElementsOptionsMode } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripeSetupModal from "../../components/StripeSetupModal/StripeSetupModal";

const stripePromise = loadStripe(config.STRIPE.publishKey);

type PricingPlan = {
  slug: BillingPlan;
  title: string;
  price: string;
  description: string;
  features: string[];
  isHighlighted: boolean;
  isCustom: boolean;
};

const pricingPlans: PricingPlan[] = [
  {
    slug: BillingPlan.starter_01,
    title: "100 Credits Starter",
    price: "5",
    description:
      "Ideal for personal use and small projects. Enough for a month of light daily monitoring.",
    features: [
      "Get Alerts when keywords are mentioned or when specific users post",
      "Approx 3-10 alerts processed per day",
      "All features included (Realtime alerts, Ai keyword filters, Zapier etc integration, webhooks, telegram & email)",
      "$0.05 per credit overage",
    ],
    isHighlighted: false,
    isCustom: false,
  },
  {
    slug: BillingPlan.bundle_01,
    title: "1000 Credits Indie Bundle",
    price: "37",
    description:
      "Ideal for solopreneurs, creators & indie small business. Enough for a month of niche-wide coverage.",
    features: [
      "Everything in the Starter Plan, plus...",
      "Approx 30-100 alerts processed per day",
      "REST API Access",
      "$0.037 per credit overage",
    ],
    isHighlighted: true,
    isCustom: false,
  },
  {
    slug: BillingPlan.custom,
    title: "Set Quantity",
    price: "Custom",
    description:
      "Ideal for agencies, startups and enterprise. Unlimited coverage with generous discounts. All features included.",
    features: [],
    isHighlighted: false,
    isCustom: true,
  },
];

const faqItems: CollapseProps["items"] = [
  {
    key: "1",
    label: "How do credits work?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "2",
    label: "How does Alerts.boo help me?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "3",
    label: "Can I use Alerts.boo to build my app?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "4",
    label: "What happens if I run out of credits?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "5",
    label: "Do credits expire?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "6",
    label: "What is the refund & cancellation policy?",
    children: <p>Lorem Ipsum</p>,
  },
  {
    key: "7",
    label: "Can I change my plan later?",
    children: <p>Lorem Ipsum</p>,
  },
];

const BuyPage = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { firebaseUser, firestoreUser, clearUsers } = useUserStore(
    (state) => state
  );
  const [chosenPlan, setChosenPlan] = useState<BillingPlan>();
  const [showStripeSetupModal, setShowStripeSetupModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const [customTokenAmount, setCustomTokenAmount] = React.useState(2000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [waitingForCard, setWaitingForCard] = useState(false);
  const [renewalsChecked, setRenewalsChecked] = useState({
    [BillingPlan.starter_01]: true,
    [BillingPlan.bundle_01]: true,
    [BillingPlan.custom]: true,
  });

  useEffect(() => {
    if (
      firestoreUser?.billingPlan &&
      firestoreUser?.billingPlan !== BillingPlan.free_01
    ) {
      setRenewalsChecked({
        [BillingPlan.starter_01]: false,
        [BillingPlan.bundle_01]: false,
        [BillingPlan.custom]: false,
      });
    }
  }, []);

  if (!firebaseUser) {
    return <div>Not Logged In</div>;
  }

  const customPricing = {
    min: 1000,
    max: 40000,
  };
  const customUnitPrice = (() => {
    for (let i = 0; i < graduatedPricing.length; i++) {
      if (customTokenAmount < graduatedPricing[i].milestone) {
        return graduatedPricing[i].unitPrice;
      }
    }
    return 0.03;
  })();

  const handleCheckout = async (plan: BillingPlan, override = false) => {
    setChosenPlan(plan);
    if (!firestoreUser?.paymentCard?.stripePaymentMethodID && !override) {
      setShowStripeSetupModal(true);
      return;
    }
    setIsLoading(true);
    setIsModalOpen(true);
    const idToken = await firebaseUser.getIdToken();
    // @ts-ignore
    const isSubscription = renewalsChecked[plan];

    // One-time purchase
    let creditsAmount = 0;
    if (plan === BillingPlan.starter_01) {
      creditsAmount = 100;
    } else if (plan === BillingPlan.bundle_01) {
      creditsAmount = 1000;
    } else if (plan === BillingPlan.custom) {
      creditsAmount = customTokenAmount;
    }
    axios
      .post(
        config.REST_API.stripePaymentIntent.url(firebaseUser.uid as UserID),
        {
          plan,
          amount: creditsAmount,
          isSubscription: isSubscription,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response: any) => {
        console.log("response", response);
        setErrorMessage("");
        messageApi.success(
          `Successfully purchased ${creditsAmount} credits`,
          60
        );
        setIsLoading(false);
        setIsModalOpen(false);
      })
      .catch((error) => {
        // Check if the error response exists and has a data property
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          console.log(error.response.data.message); // This should log "Failed to purchase - Already subscribed to this plan"
          setErrorMessage(error.response.data.message); // Sets the custom server message
          messageApi.error(error.response.data.message); // Displays the custom server message
        } else {
          console.log(JSON.stringify(error));
          setErrorMessage(error.message);
          messageApi.error("Error purchasing credits"); // Fallback error message
        }
        setIsLoading(false);
        setIsModalOpen(false);
      });
  };

  const cancelSubscription = async () => {
    const idToken = await firebaseUser.getIdToken();
    axios
      .post(
        config.REST_API.stripePaymentIntent.url(firebaseUser.uid as UserID),
        {
          plan: BillingPlan.free_01,
          amount: 0,
          isSubscription: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response: any) => {
        console.log("response", response);
        messageApi.success(`Successfully downgraded to free plan`, 60);
      })
      .catch((error) => {
        console.log(error);
        console.log(JSON.stringify(error));
        setErrorMessage(error.message);
        messageApi.error("Error purchasing credits");
      });
  };

  return (
    <Elements stripe={stripePromise}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {contextHolder}
        <h1>Buy Credits</h1>
        <Statistic
          title="Wallet Balance"
          value={firestoreUser?.walletCreditBalance.toFixed(2) || 0}
          prefix={<ThunderboltFilled />}
        />
        <br />
        <Input
          addonBefore="Current Plan"
          value={firestoreUser?.billingPlanLabel}
          style={{ maxWidth: "420px" }}
          addonAfter={
            <Select
              value="Manage"
              onSelect={(v) => {
                if (v === "Portal") {
                  window.open(config.STRIPE.customerPortalUrl, "_blank");
                } else if (v === "Cancel") {
                  cancelSubscription();
                }
              }}
              style={{ width: "100px" }}
            >
              <Select.Option value="Portal">Portal</Select.Option>
              <Select.Option value="Cancel">Cancel</Select.Option>
            </Select>
          }
        />
        <br />
        <br />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Row gutter={[16, 16]} justify="center" style={{ width: "100%" }}>
          {pricingPlans.map((plan) => (
            <Col
              xs={24}
              sm={24}
              md={isMobile ? 24 : 8}
              key={plan.title}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Card
                title={
                  <$Horizontal
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <$Vertical style={{ padding: "15px 5px" }}>
                      <Statistic
                        value={
                          plan.isCustom &&
                          customTokenAmount >= customPricing.max
                            ? "Enterprise"
                            : plan.price
                        }
                        precision={0}
                        valueStyle={{ fontSize: "2.3rem" }}
                        prefix={plan.isCustom ? "" : "$"}
                      />
                      <span>
                        {plan.isCustom && customTokenAmount >= customPricing.max
                          ? "Contact Us"
                          : plan.title}
                      </span>
                    </$Vertical>
                    {plan.isHighlighted && (
                      <Tag color="blue" style={{ padding: "5px 10px" }}>
                        Most Popular
                      </Tag>
                    )}
                  </$Horizontal>
                }
                headStyle={{ textAlign: "left" }}
                bordered={false}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  maxWidth: "600px",
                  boxShadow: plan.isHighlighted
                    ? "0px 4px 20px rgba(0, 162, 255, 0.7)"
                    : "0px 4px 20px rgba(128, 128, 128, 0.3)", // Softer shadow with color opacity
                  transition: "box-shadow 0.3s", // Optional: for smooth shadow transition
                }}
              >
                <div
                  style={{
                    height: isMobile ? "auto" : "400px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <p style={{ margin: 0, textAlign: "left" }}>
                      {plan.description}
                    </p>
                    {plan.isCustom && (
                      <$Vertical
                        alignItems="flex-start"
                        height="auto"
                        style={{ marginBottom: "20px", marginTop: "30px" }}
                      >
                        <Slider
                          defaultValue={customTokenAmount}
                          onChange={(amount) => setCustomTokenAmount(amount)}
                          value={customTokenAmount}
                          min={customPricing.min}
                          max={customPricing.max}
                          style={{ width: "100%" }}
                        />
                        {customTokenAmount >= customPricing.max && (
                          <p style={{ textAlign: "left" }}>
                            Enterprise Customers should contact us for bulk
                            pricing.
                          </p>
                        )}

                        <InputNumber
                          min={customPricing.min}
                          max={customPricing.max}
                          style={{ margin: "5px 0px", width: "100%" }}
                          value={customTokenAmount}
                          addonBefore="Credits"
                          addonAfter={`$${customUnitPrice} each`}
                          onChange={(amount) =>
                            setCustomTokenAmount(amount || customPricing.min)
                          }
                        />

                        <b
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: 500,
                            color: "gray",
                          }}
                        >{`${
                          customTokenAmount >= customPricing.max ? ">" : ""
                        }$${(customUnitPrice * customTokenAmount).toFixed(
                          0
                        )} USD`}</b>
                      </$Vertical>
                    )}
                    <ul style={{ textAlign: "left" }}>
                      {plan.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  {plan.isCustom && customTokenAmount >= customPricing.max ? (
                    <Button
                      type="primary"
                      style={{ width: "100%", marginTop: isMobile ? 16 : 0 }}
                    >
                      Contact Us
                    </Button>
                  ) : (
                    <$Vertical
                      justifyContent="flex-end"
                      style={{ marginTop: isMobile ? 16 : 0 }}
                      spacing={16}
                    >
                      <$Horizontal height="auto">
                        <Switch
                          checkedChildren={<ReloadOutlined />}
                          unCheckedChildren={<PauseOutlined />}
                          defaultChecked
                          checked={
                            // @ts-ignore
                            renewalsChecked[plan.slug]
                          }
                          onChange={(checked) =>
                            setRenewalsChecked({
                              ...renewalsChecked,
                              [plan.slug]: checked,
                            })
                          }
                        />
                        <label style={{ marginLeft: "10px" }}>
                          {
                            // @ts-ignore
                            renewalsChecked[plan.slug]
                              ? "Renew Monthly (credits never expire)"
                              : "One Time Purchase (credits never expire)"
                          }
                        </label>
                      </$Horizontal>
                      <Button
                        type="primary"
                        ghost={
                          !plan.isHighlighted ||
                          customTokenAmount >= customPricing.max
                        }
                        style={{ width: "100%" }}
                        onClick={() => handleCheckout(plan.slug)}
                        disabled={isLoading}
                      >
                        {
                          // @ts-ignore
                          renewalsChecked[plan.slug] ? "Subscribe" : `Buy`
                        }
                      </Button>
                    </$Vertical>
                  )}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <br />
        <br />
        <$Vertical>
          <h3>Frequently Asked Questions</h3>
          <Collapse
            items={faqItems}
            style={{ width: "100%", textAlign: "left" }}
          />
        </$Vertical>
        {showStripeSetupModal && (
          <StripeSetupModal
            isOpen={showStripeSetupModal}
            setIsOpen={setShowStripeSetupModal}
            onFinish={() => {
              setTimeout(() => {
                setShowStripeSetupModal(false);
                setIsModalOpen(true);
                setWaitingForCard(true);
                setTimeout(() => {
                  setWaitingForCard(false);
                  if (chosenPlan) {
                    handleCheckout(chosenPlan, true);
                  }
                }, 5000);
              }, 3000);
            }}
          />
        )}
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            {waitingForCard ||
            !firestoreUser?.paymentCard?.stripePaymentMethodID ? (
              <Result
                icon={<LoadingOutlined />}
                title="Loading Card..."
                subTitle="This should only take a few seconds."
              />
            ) : (
              <Result
                icon={<ShoppingFilled />}
                title="Buying Package..."
                subTitle="This should only take a few seconds."
              />
            )}
          </Modal>
        )}
      </div>
    </Elements>
  );
};

export default BuyPage;
