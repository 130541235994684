export const InstagramSampleAlert = {
  id: "8b499cdd-a6f9-4072-ab87-d6fed7453779",
  createdAtUnix: 1708820291,
  logID: "10c09899-62c0-48d6-840f-28ad7ac1e220",
  watcherID: "121356d3-d8fb-479b-9d2c-6112a21926b9",
  platform: "Instagram",
  type: "instagram_post",
  url: "https://instagram.com/p/C3wASNMvndr",
  data: {
    id: "3310146977240348523",
    slug: "C3wASNMvndr",
    author: "naijanation",
    url: "https://instagram.com/p/C3wASNMvndr",
    caption:
      "@_faithb_ & @chidi are in the studio! 🎙️\nCan you guess who is their special guest? 👀\n\n#NaijaProud #NaijaStrong #NaijaNation \n\n#Naija #nigerian #FYP #explore #nigerianwedding #Africanpeople #nigeriansindiaspora",
    location: "",
    medias: [
      {
        type: "image",
        url: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p1080x1080&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&ig_cache_key=MzMxMDE0Njk3NzI0MDM0ODUyMw%3D%3D.2-ccb7-5&oh=00_AfC7BLRKcRTT-jl-eLuAlut64Rqlnjw1fntduNN709Smzg&oe=65DEFD7B&_nc_sid=bc0c2c",
        thumbnail:
          "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p1080x1080&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&ig_cache_key=MzMxMDE0Njk3NzI0MDM0ODUyMw%3D%3D.2-ccb7-5&oh=00_AfC7BLRKcRTT-jl-eLuAlut64Rqlnjw1fntduNN709Smzg&oe=65DEFD7B&_nc_sid=bc0c2c",
      },
    ],
    timestamp: "2024-02-25T00:18:11.000Z",
  },
  raw: {
    __typename: "GraphImage",
    id: "3310146977240348523",
    gating_info: null,
    fact_check_overall_rating: null,
    fact_check_information: null,
    media_overlay_info: null,
    sensitivity_friction_info: null,
    sharing_friction_info: {
      should_have_sharing_friction: false,
      bloks_app_url: null,
    },
    dimensions: { height: 1350, width: 1080 },
    display_url:
      "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p1080x1080&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&ig_cache_key=MzMxMDE0Njk3NzI0MDM0ODUyMw%3D%3D.2-ccb7-5&oh=00_AfC7BLRKcRTT-jl-eLuAlut64Rqlnjw1fntduNN709Smzg&oe=65DEFD7B&_nc_sid=bc0c2c",
    display_resources: [
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p640x640_sh0.08&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfA0nsQU6c_Wh3_xwoR6zovn0uCtmNVuTPgl2egoM6Aycw&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 640,
        config_height: 800,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p750x750_sh0.08&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfDVhZtKtStwMI0FBgtMD14vgwf6qNj0f72PIoXjuWjwQw&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 750,
        config_height: 937,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=dst-jpg_e35_p1080x1080&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfC7BLRKcRTT-jl-eLuAlut64Rqlnjw1fntduNN709Smzg&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 1080,
        config_height: 1350,
      },
    ],
    is_video: false,
    media_preview:
      "ACIqz7j7xVuCOKhFWZE3detVdpBxWKOicXuTRr26k9q1o0wOmPpVW3Qj5uMmr4BXGcc+maDNKwu2in0UizFJzTI4HlG5QzAdSKYXwK6C0O1APRR/KmkXOVikIXA/iGMdv/rURiQsC7E47AEDv1z/AErUlnK4x3ANVp7gqCVKjC5wQOtPyRj5sTNFXRj2oosHMca2Sea6OOTYM9e1ZUozn6VfHSmi5qzsXUuF6EcDp3/nU4aN/T8RWYKevWmZGptX2oqlRTEf/9k=",
    tracking_token:
      "eyJ2ZXJzaW9uIjo1LCJwYXlsb2FkIjp7ImlzX2FuYWx5dGljc190cmFja2VkIjp0cnVlLCJ1dWlkIjoiOTA1MmJhZDQzMDNlNGEyMDk5MjRlMzc4MjBkZThiYmUzMzEwMTQ2OTc3MjQwMzQ4NTIzIiwic2VydmVyX3Rva2VuIjoiMTcwODgzNDIwNjc5MHwzMzEwMTQ2OTc3MjQwMzQ4NTIzfDY0MjQzODc1NjEwfGY5OTYzYjcyMzY0N2U4YTBkZjAzY2Y0MGYzZDI3MmI3ZTJmYjYwODcwNzQxZjQxNDFmNmU4MDI1OGY5ODUwOWYifSwic2lnbmF0dXJlIjoiIn0=",
    has_upcoming_event: false,
    edge_media_to_tagged_user: {
      edges: [
        {
          node: {
            user: {
              full_name: "Naija Nation",
              followed_by_viewer: false,
              id: "39040496681",
              is_verified: false,
              profile_pic_url:
                "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-19/161058026_1597492437305769_5428196161791185588_n.jpg?stp=dst-jpg_e0_s150x150&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=J8b8jHN_LJYAX9pY9cn&edm=APU89FABAAAA&ccb=7-5&oh=00_AfB7U-79CAUmd_AvpM9n78VPNb5_LrAAqBBPSMyVaTzcyQ&oe=65DF3920&_nc_sid=bc0c2c",
              username: "naijanation",
            },
            x: 0.7749221184,
            y: 0.6643302181,
          },
        },
      ],
    },
    accessibility_caption: null,
    edge_media_to_caption: {
      edges: [
        {
          node: {
            text: "@_faithb_ & @chidi are in the studio! 🎙️\nCan you guess who is their special guest? 👀\n\n#NaijaProud #NaijaStrong #NaijaNation \n\n#Naija #nigerian #FYP #explore #nigerianwedding #Africanpeople #nigeriansindiaspora",
          },
        },
      ],
    },
    shortcode: "C3wASNMvndr",
    edge_media_to_comment: {
      count: 4,
      page_info: { has_next_page: true, end_cursor: "" },
    },
    edge_media_to_sponsor_user: { edges: [] },
    is_affiliate: false,
    is_paid_partnership: false,
    comments_disabled: false,
    taken_at_timestamp: 1708820291,
    edge_media_preview_like: {
      count: -1,
      edges: [
        {
          node: {
            id: "13326264924",
            profile_pic_url:
              "https://instagram.ffru1-4.fna.fbcdn.net/v/t51.2885-19/44884218_345707102882519_2446069589734326272_n.jpg?_nc_ht=instagram.ffru1-4.fna.fbcdn.net&_nc_cat=1&_nc_ohc=akKp9od0V_AAX8_F-MG&edm=ANct6CgBAAAA&ccb=7-5&ig_cache_key=YW5vbnltb3VzX3Byb2ZpbGVfcGlj.2-ccb7-5&oh=00_AfA_zLEA6Khg5vF1pFSMUfJVDbimYDyDwglaSKN0NN5C2w&oe=65DFE88F&_nc_sid=e9e035",
            username: "hoodjabi_jdenj",
          },
        },
      ],
    },
    owner: { id: "63172612288", username: "novexpodcast" },
    location: null,
    nft_asset_info: null,
    viewer_has_liked: false,
    viewer_has_saved: false,
    viewer_has_saved_to_collection: false,
    viewer_in_photo_of_you: false,
    viewer_can_reshare: true,
    thumbnail_src:
      "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s640x640_sh0.08&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&ig_cache_key=MzMxMDE0Njk3NzI0MDM0ODUyMw%3D%3D.2.c-ccb7-5&oh=00_AfBFXjekyYmzEBVw0WGY6ErHrJOC2oQSpYjaw5n4UiV5PA&oe=65DEFD7B&_nc_sid=bc0c2c",
    thumbnail_resources: [
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s150x150&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfCuxPe8yDX6Rajb46loU-Hqz5DEtCy2T96asK7xkC--nQ&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 150,
        config_height: 150,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s240x240&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfCKW4fd1LDqtYfQxzkI91h9FVGHLKHDFZB4N2Jg4haAFQ&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 240,
        config_height: 240,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s320x320&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfBsYJRpu4bmfz8x46egie8Zq8vb7vFChCfPv-TbXI4Ybg&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 320,
        config_height: 320,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s480x480&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfD6r-_Pyvs_Rwu76evYX3NgkixWbrQeXG1y8SFZoMlFZQ&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 480,
        config_height: 480,
      },
      {
        src: "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-15/429641922_932138511465441_7905905999515250029_n.jpg?stp=c0.180.1440.1440a_dst-jpg_e35_s640x640_sh0.08&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=WtRSbK6BjE0AX9H8zwg&edm=APU89FABAAAA&ccb=7-5&oh=00_AfBFXjekyYmzEBVw0WGY6ErHrJOC2oQSpYjaw5n4UiV5PA&oe=65DEFD7B&_nc_sid=bc0c2c",
        config_width: 640,
        config_height: 640,
      },
    ],
    coauthor_producers: [
      {
        id: "39040496681",
        is_verified: false,
        profile_pic_url:
          "https://instagram.fvno7-1.fna.fbcdn.net/v/t51.2885-19/161058026_1597492437305769_5428196161791185588_n.jpg?stp=dst-jpg_e0_s150x150&_nc_ht=instagram.fvno7-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=J8b8jHN_LJYAX9pY9cn&edm=APU89FABAAAA&ccb=7-5&oh=00_AfB7U-79CAUmd_AvpM9n78VPNb5_LrAAqBBPSMyVaTzcyQ&oe=65DF3920&_nc_sid=bc0c2c",
        username: "naijanation",
      },
    ],
    pinned_for_users: [],
  },
};
