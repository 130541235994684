import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ConfigProvider } from "antd";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import WatchersPage from "./pages/WatchersPage/WatchersPage";
import AuthGuard from "./AuthGuard";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import CreateWatcherPage from "./pages/CreateWatcher/CreateWatcher";

import HomePage from "./pages/HomePage/HomePage";
import BuyPage from "./pages/BuyPage/BuyPage";
import WatcherPage from "./pages/WatcherPage/WatcherPage";

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            /* here is your component tokens */
            collapsedWidth: 0,
          },
        },
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/login" element={<HomePage />} />
            <Route path="/" element={<MainLayout />}>
              <Route
                path="watchers"
                element={
                  <AuthGuard page={<WatchersPage />} redirectPath="/login" />
                }
              />
              <Route
                path="watchers/create"
                element={
                  <AuthGuard
                    page={<CreateWatcherPage />}
                    redirectPath="/login"
                  />
                }
              />
              <Route
                path="watchers/:watcherID"
                element={
                  <AuthGuard page={<WatcherPage />} redirectPath="/login" />
                }
              />
              <Route
                path="settings"
                element={
                  <AuthGuard page={<SettingsPage />} redirectPath="/login" />
                }
              />
              <Route path="tutorials" element={<div>Tutorials</div>} />
              <Route path="buy" element={<BuyPage />} />
              <Route path="billing" element={<div>Billing</div>} />
              {/* Define other nested routes here */}
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
