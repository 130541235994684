import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUserStore } from "./store";
import { Spin } from "antd";

interface AuthGuardProps {
  page: React.ReactNode;
  redirectPath: string;
}
const AuthGuard = ({ page, redirectPath = "/login" }: AuthGuardProps) => {
  const location = useLocation();

  const { firebaseUser, loaded } = useUserStore((state) => state);

  const isAuthenticated = firebaseUser;

  console.log("firebaseUser", firebaseUser);
  console.log("loaded", loaded);
  console.log("isAuthenticated", isAuthenticated);

  if (!loaded) {
    return (
      <Spin tip="Loading...">
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </Spin>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <>{page}</>;
};

export default AuthGuard;
