// components/MainLayout.tsx

import React, { useEffect, useState } from "react";
import { Menu, Switch, Divider, Button, Drawer, Statistic } from "antd";
import {
  ReadOutlined,
  MenuOutlined,
  BellOutlined,
  SettingOutlined,
  QuestionOutlined,
  ThunderboltOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import { Link, Outlet, useNavigate } from "react-router-dom"; // Outlet is used to render the matched child route
import type { GetProp, MenuProps } from "antd";
import { useMediaQuery } from "react-responsive";
import { useUserStore } from "./store";

type MenuTheme = GetProp<MenuProps, "theme">;
type MenuItem = GetProp<MenuProps, "items">[number];

const MainLayout: React.FC = () => {
  // Define your menu items and state for mode and theme as in your original App component
  // For simplicity, I'll not repeat the items and state handling logic here
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const { firebaseUser, firestoreUser, loaded } = useUserStore(
    (state) => state
  );

  const isAuthenticated = firebaseUser;

  const [openDrawer, setOpenDrawer] = useState(false);

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Alerts", "/watchers", <BellOutlined />),
    getItem(
      <a
        href="https://entropys-organization.gitbook.io/alerts.boo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Guide
      </a>,
      "guide",
      <ReadOutlined />
    ),
    // getItem("Billing", "/billing", <BarChartOutlined />),
    getItem("Settings", "/settings", <SettingOutlined />),
  ];

  return (
    <div style={{ display: "flex" }}>
      {!loaded || !isAuthenticated ? null : (
        <>
          {isMobile ? (
            <Drawer
              title="🔔 Alerts.boo"
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
              width={250}
              placement="left"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Button
                    block
                    icon={<BellOutlined />}
                    size="large"
                    onClick={() => {
                      navigate("/watchers");
                      setOpenDrawer(false);
                    }}
                  >
                    Alerts
                  </Button>
                  <br />
                  <br />
                  <Button
                    block
                    icon={<ReadOutlined />}
                    size="large"
                    onClick={() => {
                      window.open("https://google.com", "_blank");
                      setOpenDrawer(false);
                    }}
                  >
                    Tutorial
                  </Button>
                  <br />
                  <br />
                  <Button
                    block
                    icon={<SettingOutlined />}
                    size="large"
                    onClick={() => {
                      navigate("/settings");
                      setOpenDrawer(false);
                    }}
                  >
                    Settings
                  </Button>
                  <br />
                  <br />
                </div>
                <div>
                  <Statistic
                    title="Wallet Balance"
                    value={firestoreUser?.walletCreditBalance || 0}
                    precision={2}
                    prefix={<ThunderboltFilled />}
                  />
                  <Button
                    style={{ marginTop: 16 }}
                    type="primary"
                    onClick={() => {
                      navigate("/buy");
                      setOpenDrawer(false);
                    }}
                  >
                    Buy More
                  </Button>
                </div>
              </div>
            </Drawer>
          ) : (
            <div
              style={{
                width: 256,
                height: "100vh",
                maxHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  padding: "30px 50px 30px 50px",
                  fontSize: "1.2rem",
                  borderRight: "1px solid #f0f0f0",
                }}
              >
                🔔 Alerts.boo
              </div>
              <Menu
                mode={"inline"} // Use state-controlled mode
                theme={"light"} // Use state-controlled theme
                items={items}
                style={{ height: "100vh" }}
                onSelect={(e) => {
                  console.log("clicked menu", e);
                  if (e.key.indexOf("/") > -1) {
                    navigate(e.key);
                  }
                }}
              />
              {
                // current page is not /buy
                window.location.pathname !== "/buy" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <Statistic
                      title="Wallet Balance"
                      value={firestoreUser?.walletCreditBalance || 0}
                      precision={2}
                      prefix={<ThunderboltOutlined />}
                    />
                    <Button
                      style={{ marginTop: 16, width: "100%" }}
                      type="primary"
                      ghost
                      onClick={() => navigate("/buy")}
                    >
                      Buy More
                    </Button>
                  </div>
                )
              }
            </div>
          )}
        </>
      )}

      <div style={{ flex: 1, padding: "0px" }}>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              padding: "5px",
            }}
          >
            <MenuOutlined
              onClick={() => setOpenDrawer(true)}
              style={{ margin: "5px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <b
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                🔔 Alerts.boo
              </b>
            </div>
            <ReadOutlined
              onClick={() => {
                window.open("https://google.com", "_blank");
              }}
              style={{ margin: "5px" }}
            />
          </div>
        ) : null}
        <Outlet /> {/* This renders the current route's component */}
      </div>
    </div>
  );
};

export default MainLayout;
