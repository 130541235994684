// SettingsPage.tsx
import React, { useEffect } from "react";
import { loadFirestoreUser, useUserStore } from "../../store";
import { getAuth, signOut } from "firebase/auth";
import {
  Button,
  Divider,
  Input,
  Popconfirm,
  Select,
  Spin,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { RedoOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  BillingPlan,
  BillingPlanShown,
  UserID,
} from "@entropyinternet/alertsboo-helpers";
import config from "../../config.env";
import { useWatcherStore } from "../../store/watchers.store";
import $Vertical from "../../components/utility/Vertical";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripeSetupModal from "../../components/StripeSetupModal/StripeSetupModal";
import axios from "axios";

const stripePromise = loadStripe(config.STRIPE.publishKey);

const SettingsPage = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { firebaseUser, firestoreUser, clearUsers } = useUserStore(
    (state) => state
  );
  const { clearWatchers } = useWatcherStore((state) => state);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingRotate, setLoadingRotate] = React.useState(false);
  const [showStripeSetupModal, setShowStripeSetupModal] = React.useState(false);

  console.log("firebaseUser", firebaseUser);
  console.log("firestoreUser", firestoreUser);

  if (!firebaseUser) {
    return <div>Not Logged In</div>;
  }

  const handleLogout = async () => {
    setLoading(true);
    try {
      // Sign out the user
      await signOut(auth);
      console.log("User signed out successfully");
      await Promise.all([await clearWatchers(), await clearUsers()]);
      navigate("/login");
      setLoading(false);
      // Here, you might want to redirect the user to the login page or perform other clean-up actions
    } catch (error) {
      console.error("Error signing out: ", error);
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    setErrorMessage("");
    setLoadingRotate(true);
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.rotateToken.url(firebaseUser.uid as UserID),
        {
          method: "POST",
          headers,
          redirect: "follow",
        }
      );
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        setLoadingRotate(false);
      } else {
        console.log("createWatcher result", result);
        setLoadingRotate(false);
        setErrorMessage("");
        messageApi.open({
          type: "success",
          content: "Successfully Rotated API Key",
        });
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
  };

  const removeCard = async (paymentMethodID: string | undefined) => {
    if (!paymentMethodID) {
      return;
    }
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(
        config.REST_API.stripeRemoveCard.url(firebaseUser.uid as UserID),
        {
          method: "POST",
          headers,
          body: JSON.stringify({ paymentMethodID }),
          redirect: "follow",
        }
      );
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        message.error("Error Removing Card");
      } else {
        setErrorMessage("");
        messageApi.success("Successfully Removed Card");
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
      messageApi.error("Error Removing Card");
    }
  };

  const cancelSubscription = async () => {
    const idToken = await firebaseUser.getIdToken();
    axios
      .post(
        config.REST_API.stripePaymentIntent.url(firebaseUser.uid as UserID),
        {
          plan: BillingPlan.free_01,
          amount: 0,
          isSubscription: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response: any) => {
        console.log("response", response);
        messageApi.success(`Successfully downgraded to free plan`, 60);
      })
      .catch((error) => {
        console.log(error);
        console.log(JSON.stringify(error));
        setErrorMessage(error.message);
        messageApi.error("Error purchasing credits");
      });
  };

  return (
    <Elements stripe={stripePromise}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {contextHolder}
        <h1>Settings</h1>
        <p>{firebaseUser ? firebaseUser.email : "No user logged in"}</p>

        <div style={{ width: "100%", maxWidth: "500px" }}>
          <Input
            addonBefore="API Key"
            addonAfter={
              loadingRotate ? (
                <Spin />
              ) : (
                <Popconfirm
                  title="Are you sure?"
                  description="Rotating this API Key will give you a new key and cause the old API Key to stop working. Are you sure you want to do this?"
                  onConfirm={refreshToken}
                  okText="Yes"
                  cancelText="No"
                >
                  <RedoOutlined />
                </Popconfirm>
              )
            }
            value={firestoreUser?.apiToken || ""}
          />
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Input
            addonBefore="Credits Balance"
            value={
              firestoreUser
                ? `${parseFloat(firestoreUser.walletCreditBalance.toFixed(2))}`
                : 0
            }
          />
          <Button
            type="primary"
            ghost
            onClick={() => navigate("/buy")}
            style={{ marginLeft: "5px" }}
          >
            Buy More
          </Button>
        </div>
        <br />
        <Input
          addonBefore="Current Plan"
          addonAfter={
            <Select
              value="Manage"
              onSelect={(v) => {
                if (v === "Portal") {
                  window.open(config.STRIPE.customerPortalUrl, "_blank");
                } else if (v === "Change") {
                  navigate("/buy");
                } else if (v === "Cancel") {
                  cancelSubscription();
                }
              }}
              style={{ width: "100px" }}
            >
              <Select.Option value="Change">Change</Select.Option>
              <Select.Option value="Portal">Portal</Select.Option>
              <Select.Option value="Cancel">Cancel</Select.Option>
            </Select>
          }
          value={firestoreUser?.billingPlanLabel}
          style={{ maxWidth: "400px" }}
        />
        <br />
        {firestoreUser?.paymentCard?.stripePaymentMethodID ? (
          <Input
            addonBefore="Credit Card"
            addonAfter={
              <Popconfirm
                title="Remove Card"
                description="Are you sure to remove this credit card? You will need to add a new card to continue using the service."
                onConfirm={() =>
                  removeCard(firestoreUser?.paymentCard?.stripePaymentMethodID)
                }
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <CloseOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            }
            value={`Ending in ${firestoreUser?.paymentCard?.last4}`}
            style={{ maxWidth: "400px" }}
          />
        ) : (
          <Button onClick={() => setShowStripeSetupModal(true)}>
            Add Credit Card
          </Button>
        )}

        {showStripeSetupModal && (
          <StripeSetupModal
            isOpen={showStripeSetupModal}
            setIsOpen={setShowStripeSetupModal}
            onFinish={() => {
              navigate("/buy");
              window.location.reload();
            }}
          />
        )}

        <Divider />
        <i
          style={{ fontSize: "0.8rem", color: "gray" }}
        >{`UserID: ${firebaseUser.uid}`}</i>
        <br />
        <br />
        <Button danger loading={loading} onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </Elements>
  );
};

export default SettingsPage;
