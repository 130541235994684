import { create } from "zustand";
import {
  FirestoreCollection,
  UserID,
  User_Firestore,
} from "@entropyinternet/alertsboo-helpers";
import { User as User_Firebase } from "firebase/auth";
import { getDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "..";

interface UserStoreProps {
  firebaseUser: User_Firebase | null;
  firestoreUser: User_Firestore | null;
  loaded: boolean;
  clearUsers: () => void;
}
export const useUserStore = create<UserStoreProps>((set) => ({
  firebaseUser: null,
  firestoreUser: null,
  loaded: false,
  clearUsers: () => {
    set({ firebaseUser: null, firestoreUser: null });
  },
}));

export const loadFirestoreUser = async (uid: UserID) => {
  try {
    const docRef = doc(db, FirestoreCollection.Users, uid); // Create a reference to the document
    onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const firestoreUser = docSnap.data() as User_Firestore;
          useUserStore.setState({ firestoreUser, loaded: true });
        } else {
          console.log("User not found!");
        }
      },
      (error) => {
        console.error("Failed to subscribe to user updates:", error);
      }
    );
    // const docSnap = await getDoc(docRef); // Fetch the document
    // if (docSnap.exists()) {
    //   const firestoreUser = docSnap.data() as User_Firestore;
    //   useUserStore.setState({ firestoreUser });
    // } else {
    //   console.log("User not found!");
    // }
  } catch (e) {
    console.log(e);
  }
};
