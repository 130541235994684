// CreateWatcherPage.tsx
import React, { useEffect, useState } from "react";
import { useUserStore } from "../../store";
import { getAuth, signOut } from "firebase/auth";
import { Button, Input, Tooltip, message, Dropdown, Space, Select } from "antd";
import { QuestionCircleFilled, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import config from "../../config.env";
import type { MenuProps } from "antd";
import { WatcherPlatform, UserID } from "@entropyinternet/alertsboo-helpers";
import {
  loadFirestoreWatchers,
  resetWatchers,
} from "../../store/watchers.store";
import { InstagramSampleAlert } from "../../samples/instagram-alert.sample";
import axios from "axios";

export const checkUrlStringValid = (url: string): boolean => {
  if (!url) {
    return false;
  }
  try {
    console.log(`checkUrlStringValid: url = ${url}`);
    const urlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const isValid = urlRegex.test(url);
    const parsedUrl = new URL(url);
    console.log("isValid = ", isValid);
    console.log("parsedUrl = ", parsedUrl);
    if (parsedUrl && isValid) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

const WatcherPlatformToSitePrefix = {
  [WatcherPlatform.instagram]: "https://instagram.com/",
  [WatcherPlatform.twitter]: "https://x.com/",
  [WatcherPlatform.facebook]: "https://facebook.com/",
  [WatcherPlatform.reddit]: "https://reddit.com/",
  [WatcherPlatform.tiktok]: "https://tiktok.com/",
  [WatcherPlatform.youtube]: "https://youtube.com/",
  [WatcherPlatform.linkedin]: "https://linkedin.com/",
  [WatcherPlatform.twitch]: "https://twitch.com/",
  [WatcherPlatform.pinterest]: "https://pinterest.com/",
  [WatcherPlatform.discord]: "https://discord.com/",
  [WatcherPlatform.google_reviews]: "https://maps.google.com/",
  [WatcherPlatform.tumblr]: "https://tumblr.com/",
};

const CreateWatcherPage = () => {
  const [url, setUrl] = React.useState("");
  const [webhookUrl, setWebhookUrl] = React.useState("");
  const [promptFilter, setPromptFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [platform, setPlatform] = useState<WatcherPlatform>();
  const { firebaseUser } = useUserStore((state) => state);
  const navigate = useNavigate();

  if (!firebaseUser) {
    return <div>Not Logged In</div>;
  }

  const createWatcher = async () => {
    if (platform === undefined) {
      return;
    }

    const formattedUrl = WatcherPlatformToSitePrefix[platform] + url;
    if (!checkUrlStringValid(formattedUrl)) {
      setErrorMessage("Invalid URL");
      return;
    }
    if (webhookUrl && !checkUrlStringValid(webhookUrl)) {
      setErrorMessage("Invalid Webhook URL");
      return;
    }
    if (promptFilter.length > 2000) {
      setErrorMessage("Prompt Filter too long");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    const idToken = await firebaseUser.getIdToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`);
    try {
      const response = await fetch(config.REST_API.createWatcher.url, {
        method: "POST",
        headers,
        body: JSON.stringify({
          url: formattedUrl,
          webhookUrl,
          filterPrompt: promptFilter,
        }),
        redirect: "follow",
      });
      const result = await response.json();
      const { success, message } = result;
      if (!success) {
        setErrorMessage(message);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("createWatcher result", result);
        messageApi.open({
          type: "success",
          content: "Successfully Created Watcher",
        });
        await resetWatchers(firebaseUser.uid as UserID);
        setTimeout(() => {
          navigate("/watchers");
          setUrl("");
          setWebhookUrl("");
        }, 500);
      }
      return result;
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const sentSampleWebhook = async () => {
    if (!webhookUrl) {
      messageApi.warning("Please add a webhook URL first");
      return;
    }
    try {
      await axios.post(webhookUrl, InstagramSampleAlert);
      messageApi.info("Sent a sample alert to your webhook");
    } catch (e) {
      console.error("Error:", e);
      messageApi.error(
        "Error sending sample alert to your webhook. Is your webhook active?"
      );
    }
  };

  const logVote = async (platform: WatcherPlatform) => {
    fetch(
      "https://app.legions.bot/webhook/ec1756f0-a091-4e9b-ae7a-59e1864b09a9",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          platform,
          userID: firebaseUser.uid,
          email: firebaseUser.email,
          timestamp: new Date().toISOString(),
        }),
      }
    );
  };

  const onSelectPlatform = (i: any) => {
    console.log("click", i);
  };
  const items: MenuProps["items"] = [
    {
      key: WatcherPlatform.instagram,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.instagram);
            logVote(WatcherPlatform.instagram);
          }}
        >
          Instagram
        </div>
      ),
    },
    {
      key: WatcherPlatform.twitter,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.twitter);
            logVote(WatcherPlatform.twitter);
          }}
        >
          Twitter
        </div>
      ),
    },
    {
      key: WatcherPlatform.facebook,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.facebook);
            logVote(WatcherPlatform.facebook);
          }}
        >
          {WatcherPlatform.facebook}
        </div>
      ),
    },
    {
      key: WatcherPlatform.linkedin,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.linkedin);
            logVote(WatcherPlatform.linkedin);
          }}
        >
          {WatcherPlatform.linkedin}
        </div>
      ),
    },
    {
      key: WatcherPlatform.reddit,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.reddit);
            logVote(WatcherPlatform.reddit);
          }}
        >
          {WatcherPlatform.reddit}
        </div>
      ),
    },
    {
      key: WatcherPlatform.tiktok,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.tiktok);
            logVote(WatcherPlatform.tiktok);
          }}
        >
          {WatcherPlatform.tiktok}
        </div>
      ),
    },
    {
      key: WatcherPlatform.youtube,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.youtube);
            logVote(WatcherPlatform.youtube);
          }}
        >
          {WatcherPlatform.youtube}
        </div>
      ),
    },
    {
      key: WatcherPlatform.twitch,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.twitch);
            logVote(WatcherPlatform.twitch);
          }}
        >
          {WatcherPlatform.twitch}
        </div>
      ),
    },
    {
      key: WatcherPlatform.discord,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.discord);
            logVote(WatcherPlatform.discord);
          }}
        >
          {WatcherPlatform.discord}
        </div>
      ),
    },
    {
      key: WatcherPlatform.pinterest,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.pinterest);
            logVote(WatcherPlatform.pinterest);
          }}
        >
          {WatcherPlatform.pinterest}
        </div>
      ),
    },
    {
      key: WatcherPlatform.google_reviews,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.google_reviews);
            logVote(WatcherPlatform.google_reviews);
          }}
        >
          {WatcherPlatform.google_reviews}
        </div>
      ),
    },
    {
      key: WatcherPlatform.tumblr,
      label: (
        <div
          onClick={() => {
            setPlatform(WatcherPlatform.tumblr);
            logVote(WatcherPlatform.tumblr);
          }}
        >
          {WatcherPlatform.tumblr}
        </div>
      ),
    },
    {
      key: "vote-more",
      label: (
        <div
          onClick={() => {
            // open in new tab
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfWVnKt-3oUuDGWyLk14ubrkxO_Top81aZHhLNYHLJTJT5v8A/viewform",
              "_blank"
            );
          }}
        >
          Vote More
        </div>
      ),
    },
  ];

  const menuProps = {
    items,
    onClick: onSelectPlatform,
  };

  const renderPlatformUI = () => {
    if (platform === WatcherPlatform.instagram) {
      return (
        <>
          {platform && (
            <span style={{ color: "gray", fontSize: "1rem" }}>
              {`Creating an Instagram Watcher costs 5 credits/month.
You can pause at any time.`}
            </span>
          )}
          <br />

          {platform && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  rowGap: "10px",
                  width: "100%",
                }}
              >
                <label style={{ fontWeight: "bold" }}>
                  Link to Resource{" "}
                  <Tooltip title="Link to Instagram profile url">
                    <QuestionCircleFilled size={12} style={{ color: "gray" }} />
                  </Tooltip>
                </label>
                <Input
                  addonBefore={WatcherPlatformToSitePrefix[platform]}
                  value={url}
                  placeholder="username"
                  onChange={(e) =>
                    setUrl(
                      e.target.value
                        .replace("https://instagram.com/", "")
                        .replace("https://www.instagram.com/", "")
                        .replace("www.instagram.com/", "")
                    )
                  }
                  style={{ width: "450px", maxWidth: "100%" }}
                />
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  rowGap: "10px",
                  width: "100%",
                }}
              >
                <label style={{ fontWeight: "bold" }}>
                  AI Keyword Filter (optional)
                  <Tooltip title="Text prompt to tell the Ai what type of content you want to get alerts on. Should ask the Ai to return true or false. Costs credits & max 2000 chars. Leave empty for no Ai prompt filter.">
                    <QuestionCircleFilled
                      size={12}
                      style={{
                        color: "gray",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    />
                  </Tooltip>
                  {
                    <span style={{ color: "gray", fontSize: "0.7rem" }}>
                      {promptFilter.length}/2000 chars
                    </span>
                  }
                </label>
                <Input.TextArea
                  value={promptFilter}
                  onChange={(e) => setPromptFilter(e.target.value)}
                  rows={4}
                  maxLength={2000}
                  placeholder="Return true if this user posts about..."
                />
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  rowGap: "10px",
                  width: "100%",
                }}
              >
                <label style={{ fontWeight: "bold" }}>
                  Webhook (optional)
                  <Tooltip title="Link to Zapier or custom webhook url">
                    <QuestionCircleFilled
                      size={12}
                      style={{ color: "gray", marginLeft: "5px" }}
                    />
                  </Tooltip>
                </label>
                <Input
                  value={webhookUrl}
                  onChange={(e) => setWebhookUrl(e.target.value)}
                  placeholder="eg. https://zapier.com/my-webhook/abc123"
                  addonAfter={
                    <Select
                      value="Actions"
                      onSelect={(v) => {
                        if (v === "Sample") {
                          sentSampleWebhook();
                        }
                      }}
                      style={{ width: "100px" }}
                    >
                      <Select.Option value="Sample">Sample</Select.Option>
                    </Select>
                  }
                />
              </div>
              <br />
              <div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </div>
              <Button
                type="primary"
                disabled={!url}
                loading={loading}
                onClick={createWatcher}
              >
                Create
              </Button>
            </>
          )}
        </>
      );
    }
    if (platform) {
      return (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.05)",
            padding: "20px 20px 30px 20px",
            borderRadius: "10px",
            color: "rgba(0,0,0,0.6)",
            width: "200px",
          }}
        >
          <p>{`${platform} Coming Soon `}</p>
          <i>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfWVnKt-3oUuDGWyLk14ubrkxO_Top81aZHhLNYHLJTJT5v8A/viewform"
              target="_blank"
              rel="noreferrer"
              style={{ marginTop: "5px", textDecoration: "none" }}
            >
              Learn More
            </a>
          </i>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          rowGap: "10px",
          padding: "20px",
        }}
      >
        <span
          onClick={() => navigate(-1)}
          style={{ color: "gray", cursor: "pointer" }}
        >{`< Back`}</span>

        <h1>Create Watcher</h1>

        <div style={{ marginBottom: "25px" }}>
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                {platform ? platform : `Pick Platform`}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>

        {renderPlatformUI()}
      </div>
    </>
  );
};

export default CreateWatcherPage;
