import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { loadFirestoreUser, useUserStore } from "./store";
import { UserID } from "@entropyinternet/alertsboo-helpers";
import { loadFirestoreWatchers } from "./store/watchers.store";
import config from "./config.env";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs().format();
dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const app = initializeApp(config.FIREBASE);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);

onAuthStateChanged(auth, (user) => {
  console.log("Firebase auth user:", user);
  useUserStore.setState({ firebaseUser: user, loaded: true });
  if (user) {
    loadFirestoreUser(user.uid as UserID);
    loadFirestoreWatchers(user.uid as UserID);
  }
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
