import React, { CSSProperties } from "react";

interface $VerticalProps {
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  height?: string;
  width?: string;
  flexGrow?: number;
  spacing?: number;
  style?: CSSProperties; // Add style prop for custom styles
  children?: React.ReactNode;
}

// <$Vertical> component for vertical layouts with customizable size and types
const $Vertical: React.FC<$VerticalProps> = ({
  justifyContent = "flex-start",
  alignItems = "flex-start",
  height = "100%",
  width = "100%",
  flexGrow = 0,
  spacing = 8,
  style = {}, // Default to an empty object
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent,
        alignItems,
        height,
        width,
        flexGrow,
        gap: spacing,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default $Vertical;
