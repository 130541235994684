import { Button, Image } from "antd";
import { Link } from "react-router-dom";
import { useUserStore } from "../../store";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const { firebaseUser } = useUserStore((state) => state);
  const isAuthenticated = firebaseUser && firebaseUser.uid;
  const navigate = useNavigate();
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result): void => {
        console.log(result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log(credential);
        if (credential) {
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          navigate("/watchers");
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  return (
    <div>
      <h1>🔔 Alerts.boo</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "250px",
          alignItems: "center",
          rowGap: "10px",
        }}
      >
        {isAuthenticated ? (
          <Link to="/watchers">
            <Button type="primary" block style={{ width: "200px" }}>
              Go To App
            </Button>
          </Link>
        ) : (
          <Button
            type="primary"
            onClick={signInWithGoogle}
            block
            style={{ width: "200px" }}
          >
            Login with Google
          </Button>
        )}
      </div>
      <Image
        preview={false}
        style={{ marginTop: "32px", padding: "20px" }}
        src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F4ea030c8170c9e727f075de47af71e83.cdn.bubble.io%2Ff1703950706740x971736337403047000%2FGroup%252087%2520%25282%2529.png?w=384&h=469&auto=compress&fit=max"
      />
    </div>
  );
};

export default HomePage;
