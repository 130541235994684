import { create } from "zustand";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  DocumentData,
  QueryDocumentSnapshot,
  startAfter,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "..";
import {
  WatcherID,
  Watcher_Firestore,
  FirestoreCollection,
  UserID,
} from "@entropyinternet/alertsboo-helpers";

interface WatcherStoreProps {
  watchers: Watcher_Firestore[];
  loading: boolean;
  cursor: QueryDocumentSnapshot<DocumentData> | null; // Store the last document snapshot for pagination
  appendWatchers: (
    newWatchers: Watcher_Firestore[],
    newCursor: QueryDocumentSnapshot<DocumentData> | null
  ) => void;
  clearWatchers: () => void;
}

export const useWatcherStore = create<WatcherStoreProps>((set, get) => ({
  watchers: [],
  loading: false,
  cursor: null,
  appendWatchers: (
    newWatchers: Watcher_Firestore[],
    newCursor: QueryDocumentSnapshot<DocumentData> | null
  ) => {
    const { watchers } = get();
    set({ watchers: [...watchers, ...newWatchers], cursor: newCursor });
  },
  clearWatchers: () => {
    set({ watchers: [], cursor: null });
  },
}));

export const loadFirestoreWatchers = async (
  uid: UserID,
  cursor?: QueryDocumentSnapshot<DocumentData> | null,
  batchSize: number = 50
) => {
  try {
    useWatcherStore.setState({ loading: true });
    const { cursor } = useWatcherStore.getState();
    let q;

    if (cursor) {
      q = query(
        collection(db, FirestoreCollection.Watchers),
        where("userID", "==", uid),
        where("isActive", "==", true),
        orderBy("createdAt", "desc"),
        startAfter(cursor),
        limit(batchSize)
      );
    } else {
      q = query(
        collection(db, FirestoreCollection.Watchers),
        where("userID", "==", uid),
        where("isActive", "==", true),
        orderBy("createdAt", "desc"),
        limit(batchSize)
      );
    }

    const querySnapshot = await getDocs(q);
    const newWatchers: Watcher_Firestore[] = [];
    let newCursor: QueryDocumentSnapshot<DocumentData> | null = null;
    querySnapshot.forEach((doc) => {
      newWatchers.push({
        id: doc.id,
        ...doc.data(),
      } as Watcher_Firestore);
      newCursor = doc; // Update the cursor with the last document snapshot
    });
    if (newWatchers.length < batchSize) {
      newCursor = null;
    }
    useWatcherStore.getState().appendWatchers(newWatchers, newCursor);
    useWatcherStore.setState({ loading: false });
  } catch (e) {
    console.error("Error loading watchers:", e);
  }
};

export const resetWatchers = async (uid: UserID, batchSize: number = 50) => {
  try {
    useWatcherStore.setState({ loading: true });
    let q = query(
      collection(db, FirestoreCollection.Watchers),
      where("userID", "==", uid),
      where("isActive", "==", true),
      orderBy("createdAt", "desc"),
      limit(batchSize)
    );
    const querySnapshot = await getDocs(q);
    const newWatchers: Watcher_Firestore[] = [];
    let newCursor: QueryDocumentSnapshot<DocumentData> | null = null;
    querySnapshot.forEach((doc) => {
      newWatchers.push({
        id: doc.id,
        ...doc.data(),
      } as Watcher_Firestore);
      newCursor = doc; // Update the cursor with the last document snapshot
    });
    useWatcherStore.setState({
      loading: false,
      watchers: newWatchers,
      cursor: newCursor,
    });
  } catch (e) {
    console.error("Error loading watchers:", e);
  }
};

export const loadFirestoreWatcher = async (watcherID: WatcherID) => {
  try {
    const docRef = doc(db, FirestoreCollection.Watchers, watcherID); // Create a reference to the document
    const docSnap = await getDoc(docRef); // Fetch the document
    if (docSnap.exists()) {
      const firestoreWatcher = docSnap.data() as Watcher_Firestore;
      return firestoreWatcher;
    } else {
      throw new Error("Watcher not found!");
    }
  } catch (e: any) {
    throw new Error("Error loading watcher: ", e.message);
  }
};
