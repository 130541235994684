import { Button, Modal, message } from "antd";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import config from "../../config.env";
import { useUserStore } from "../../store/user.store";
import $Vertical from "../utility/Vertical";
import "./StripeSetupModal.css";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onFinish: () => void;
}
const StripeSetupModal = ({ isOpen, setIsOpen, onFinish }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { firestoreUser, firebaseUser } = useUserStore((state) => state);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (firestoreUser?.id && firebaseUser) {
      const stripeSetupIntent = async () => {
        const idToken = await firebaseUser.getIdToken();
        fetch(config.REST_API["stripeSetupIntent"].url(firestoreUser.id), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.data.clientSecret);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
      stripeSetupIntent();
    }
  }, []);

  if (!isOpen) {
    return null;
  }

  const handleSubmit = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Card Element not found");
      setIsLoading(false);
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result.error) {
      // @ts-ignore
      setError(result.error.message);
      message.error(
        result.error.message || "An error occurred adding this card"
      );
      setIsLoading(false);
    } else {
      // The setup has succeeded. The card has been attached to the customer.
      console.log("Setup succeeded:", result.setupIntent.payment_method);
      message.success("Card added successfully");
      setIsLoading(false);
      setIsOpen(false);
      onFinish();
    }
  };

  return (
    <Modal
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      {contextHolder}
      <$Vertical spacing={16}>
        <h3>Add Credit Card</h3>
        <CardElement />
        <Button
          type="primary"
          block
          onClick={handleSubmit}
          disabled={!stripe || !clientSecret}
          style={{ marginTop: 16 }}
          loading={isLoading}
        >
          Add Card
        </Button>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </$Vertical>
    </Modal>
  );
};

export default StripeSetupModal;
